<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';
export default {
	extends: GlobalVue,
	data() {
	  	return {
		    about:{},
		    address:{},
		    
		    phone:{},
		    phone_title:{},
		    phone_ctu:{},
		    phone_ctu_title:{},
		    phone_ffi:{},
		    phone_ffi_title:{},

		    email:{},
		    copy:{},
		    sosmed_fb:{},
		    sosmed_ig:{},
		    sosmed_tw:{},
		    sosmed_lk:{},
		    sosmed_yt:{},
		}
	},
	mounted(){
		this.apiGetStaticContent()
	},
	methods:{
	  	apiGetStaticContent(){
	        Gen.apirest('/static-footer', {}, (err, resp)=>{
	            if(err) console.log(err)
	            this.about=resp.data.about
	            this.address=resp.data.address

	            this.phone=resp.data.phone
	            this.phone_title=resp.data.phone_title
	            this.phone_ctu=resp.data.phone_ctu
	            this.phone_ctu_title=resp.data.phone_ctu_title
	            this.phone_ffi=resp.data.phone_ffi
	            this.phone_ffi_title=resp.data.phone_ffi_title
	            
	            this.email=resp.data.email
	            this.copy=resp.data.copy

	            this.sosmed_fb=resp.data.sosmed_fb
	            this.sosmed_ig=resp.data.sosmed_ig
	            this.sosmed_tw=resp.data.sosmed_tw
	            this.sosmed_lk=resp.data.sosmed_lk
	            this.sosmed_yt=resp.data.sosmed_yt
	        })
	    },
	}
};
</script>
<template>
  	<div Footer>
	  	<footer id="footer" class="notopmargin">
		<div class="container">
			<div class="footer-widgets-wrap clearfix">
				<div class="row">
					<div class="col-md-12 col-lg-7">
						<div class="row">
							<div class="col-md-5">
								<div class="widget clearfix">
									<div class="footer-logo">
										<VImg :src="baseUrl+'/frontend/images/logo-dark.png'" alt="Logo Farmsco" title="Logo Farmsco"></VImg>
										<div v-html="about.msc_desc"></div>
									</div>
								</div>
							</div>
							<div class="col-md-3 col-5">
								<div class="widget widget_links clearfix">
									<h4>{{ web.lbl_farmsco }}</h4>
									<ul>
										<li><router-link :to="{name:'Karir'}">{{ web.mn_karir }}</router-link></li>
										<li><router-link :to="{name:'AboutUs'}">{{ web.mn_tentang }}</router-link></li>
										<li><router-link :to="{name:'Contact'}">{{ web.mn_contact }}</router-link></li>
									</ul>
								</div>
							</div>
							<div class="col-md-4 col-7">
								<div class="widget widget_links clearfix">
									<h4>{{ web.lbl_lainnya }}</h4>
									<ul>
										<li><router-link :to="{name:'Artikel'}">{{ web.mn_artikel }}</router-link></li>
										<li><router-link :to="{name:'Toc'}">{{ web.mn_syarat_ketentuan }}</router-link></li>
										<li><router-link :to="{name:'Privacy'}">{{ web.mn_kebijakan_privasi }}</router-link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6 offset-md-5 offset-lg-0 col-lg-5">
						<div class="row">
							<div class="col-lg-6">
								<div class="widget clearfix mb-md-3 mb-lg-0">
									<h4>{{ web.lbl_temui_farmsco }}</h4>
									<div class="d-flex">

										<a v-if="sosmed_fb" :href="sosmed_fb" target="_blank" class="social-icon si-small si-rounded si-bordered si-colored si-facebook" title="Facebook">
											<i class="icon-facebook"></i>
											<i class="icon-facebook"></i>
										</a>
										<a v-if="sosmed_ig" :href="sosmed_ig" target="_blank" class="social-icon si-small si-bordered  si-rounded si-colored si-instagram" title="Instagram">
											<i class="icon-instagram"></i>
											<i class="icon-instagram"></i>
										</a>
										<a v-if="sosmed_tw" :href="sosmed_tw" target="_blank" class="social-icon si-small si-bordered  si-rounded si-colored si-twitter" title="Twitter">
											<i class="icon-twitter"></i>
											<i class="icon-twitter"></i>
										</a>
										<a v-if="sosmed_lk" :href="sosmed_lk" target="_blank" class="social-icon si-small si-bordered  si-rounded si-colored si-linkedin" title="LinkedIn">
											<i class="icon-linkedin"></i>
											<i class="icon-linkedin"></i>
										</a>
										<a v-if="sosmed_yt" :href="sosmed_yt" target="_blank"
											class="social-icon si-small si-bordered  si-rounded si-colored si-youtube"
											title="YouTube">
											<i class="icon-youtube"></i>
											<i class="icon-youtube"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="widget clearfix">
									<h4>Farmsco Indonesia</h4>
									<div v-html="address.msc_desc"></div>
									<div class="area-contact">
										<ul class="ac-list">
											<li class="ac-item">
												<a :href="'tel:'+phone"><i class="icon-phone3"></i>{{ phone }} ({{ phone_title }})</a>
											</li>
											<li class="ac-item">
												<a :href="'tel:'+phone_ctu"><i class="icon-phone3"></i>{{ phone_ctu }} ({{ phone_ctu_title }})</a>
											</li>
											<li class="ac-item">
												<a :href="'tel:'+phone_ffi"><i class="icon-phone3"></i>{{ phone_ffi }} ({{ phone_ffi_title }})</a>
											</li>
											<li class="ac-item">
												<a :href="'mailto:'+email"><i class="icon-envelope"></i>{{ email }}</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="copyrights">
			<div class="container clearfix">
				<div v-html="copy"></div>
			</div>
		</div>
	</footer>
	<div class="modal fade" id="detailProd2">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">Detail Produk</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-4">
							<h4 class="dp-img-desc">Hi-Starter</h4>
							<div class="dp-img">
								<img src="images/products-updated/hi-starter.png" alt="Foto Produk">
							</div>
						</div>
						<div class="col-md-8">
							<h4>Kandungan dan Manfaat</h4>
							<ul>
								<li>Meningkatkan keseragaman ayam</li>
								<li>Memaksimalkan daya tahan hidup</li>
								<li>Terbentuknya ontrol tubuh ayam</li>
								<li>Menguatkan kerangka tubuh ayam dan ontro pendukung</li>
							</ul>
							<span>Untuk Periode Umur 0-6 Minggu</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>